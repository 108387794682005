<template>
  <div>
    <router-view
      :boardFilterObj="boardFilterObj"
      :mediaSearch="mediaSearch"
      :messageSearch="messageSearch"
      :drawerRight="drawerRight"
      :taskView="taskView"
      :myWorkDateFilter="myWorkDateFilter"
      :myworkSearch="myworkSearch"
      :mediaView="mediaView"
      :boardSearchOptions="boardSearchOptions"
      :customFields="customField"
      :toggleDrawerRight="toggleDrawerRight"
      @closeNotificaiton="closeNotificaiton"
    />
    <profile-dialog
      v-model="profileDialog.show"
      v-if="profileDialog.show"
      :recepient="userProfileDialog"
      :user="user"
      :workspace="currentWorkspace"
      :isWorkspaceOwner="isWorkspaceOwner"
      :currentWorkspace="currentWorkspace"
      :currentCompany="currentCompany"
      @close="clearUserInfoDialog"
    ></profile-dialog>
    <q-dialog
      transition-hide="none"
      transition-show="none"
      persistent
      v-model="taskDialog"
      @before-hide="closeTaskDialog"
      @keydown.esc="resetTaskDialog"
    >
      <task-dialog
        v-if="editTaskdata"
        place="workspace"
        :tasks="editTaskdata"
        :reactions="reactions"
        :labels="taskLabels"
        :boardId="workspace.boards[0].id"
        :workspaceid="workspace.id"
        :workspace="workspace"
        :isWorkspaceOwner="isWorkspaceOwner"
        :currentUser="user"
        :authToken="currentCompany.accessToken"
        :workspaceMembers="taskWorkspaceMember"
        :taskLabels="taskLabels"
        :openSubtask="openSubtask"
        :openTaskComment="openTaskComment"
        :currentCompany="currentCompany"
        :attachTaskMediaHandler="attachTaskMedia"
        :onUpdateTaskMembersHandler="reactivityOnUpdateTaskMembers"
        :deleteTaskCommentHandler="deleteTaskComment"
        :detachTaskAttachmentHandler="detachTaskAttachment"
        @reactOnPost="reactOnPost"
        @updateMediaCache="updateMediaCache"
        @createSubTask="createSubTask"
        @close="resetTaskDialog"
        @success="taskDialogSuccessHandler"
        @clearSubtasks="clearSubtasks"
        @createComment="createComment"
        @clickonmoreoption="clickonmoreoption"
        @deleteCommentPoint="deleteCommentPoint"
        @closeMediaModel="closeMediaModel"
        @updateMediaCommentPointLabel="updateMediaCommentPointLabel"
        @updateComment="updateComment"
        @sendComment="sendComment"
        @deleteMediaComment="deleteMediaComment"
        @updatepositions="updatepositions"
        @addTaskAttchmentToMedia="addTaskAttchmentToMedia"
        @updateMediaCacheWithReactivity="updateMediaCacheWithReactivity"
        :subTaskId="subTaskId"
        :openSubtaskDialog="openSubtaskDialog"
      ></task-dialog>
    </q-dialog>

    <q-dialog
      transition-hide="none"
      transition-show="none"
      persistent
      v-model="subTaskDialog"
      @before-hide="closeSubTaskDialog"
    >
      <sub-task-dialog
        v-if="currentSubTask"
        :tasks="currentTask"
        :subTask="currentSubTask"
        :workspace="workspace"
        :workspaceid="workspace.id"
        :workspaceMembers="taskWorkspaceMember"
        :currentCompany="currentCompany"
        :authToken="currentCompany.accessToken"
        :reactions="reactions"
        @createComment="createComment"
        :openTaskComment="openTaskComment"
        :deleteTaskCommentHandler="deleteTaskComment"
        @deleteCommentPoint="deleteCommentPoint"
        @updateMediaCommentPointLabel="updateMediaCommentPointLabel"
        :taskLabels="taskLabels"
        @updateComment="updateComment"
        @sendComment="sendComment"
        @deleteMediaComment="deleteMediaComment"
        @clearSubtasks="clearChildSubtasks"
        :backToTask="backToTask"
        @createSubTask="createSubTask"
        :subTaskId="subTaskId"
      ></sub-task-dialog>
    </q-dialog>

    <confirm-dialog
      v-if="isTaskFound"
      v-model="isTaskFound"
      title="No task found"
      cancleText="Cancel"
      :cancleMethod="closeNoTaskDialoge"
    />
    <participant-video-call-dialog
      v-model="videoCallData.isDialogShow"
      v-if="videoCallData.isDialogShow"
      :roomName="videoCallData.roomName"
      :jwtToken="videoCallData.jwtToken"
      @closeDialog="closeVideoCallDialog"
    />
  </div>
</template>

<script>
import has from "lodash/has";
import { mapGetters } from "vuex";
import get from "lodash/get";
import ProfileDialog from "@/components/ProfileDialog";
import BoardsMethodMixin from "@/mixins/BoardsMethodMixin";
import MediaMethodMixin from "@/mixins/MediaMethodMixin";

import TaskDialog from "@/views/BoardView/TaskDialog";
import SubTaskDialog from "@/views/BoardView/SubTaskDialog";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";

import ParticipantVideoCallDialog from "@/components/Dialogs/ParticipantVideoCallDialog";

import isEmpty from "lodash/isEmpty";

let meetWindow;

import {
  NewMediaQuery,
  BoardQuery,
  MessagesQuery,
  UpdateNotificationsMutation,
  ActivityLogsQuery,
  PrivateTasksQuery,
  MediaFoldersQuery,
  TaskQuery,
  CustomFieldQuery,
  GenerateJwtTokenForUser,
  PinMessagesQuery,
  BookmarksQuery,
  BookmarkFoldersQuery,
  SubtaskQuery,
  DocumentsQuery,
} from "@/gql";
export default {
  name: "WorkspaceView",
  mixins: [BoardsMethodMixin, MediaMethodMixin],
  props: [
    "currentWorkspace",
    "unsubscribeAllChannel",
    "company",
    "boardFilterObj",
    "mediaSearch",
    "messageSearch",
    "drawerRight",
    "taskView",
    "myWorkDateFilter",
    "myworkSearch",
    "mediaView",
    "boardSearchOptions",
  ],
  components: {
    ProfileDialog,
    TaskDialog,
    SubTaskDialog,
    ConfirmDialog,
    ParticipantVideoCallDialog,
  },
  api: {
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
    media: {
      query: NewMediaQuery,
      defaultValue: [],
      variables() {
        return {
          offset: 0,
          workspace_id: parseInt(this.$route.params.workspace),
        };
      },
      cacheKey() {
        return `MediaQuery:${parseInt(this.$route.params.workspace)}`;
      },
    },
    mediaFolders: {
      query: MediaFoldersQuery,
      defaultValue: [],
      variables() {
        return {
          workspace_id: parseInt(this.$route.params.workspace),
        };
      },
      cacheKey() {
        return `MediaFoldersQuery:${parseInt(this.$route.params.workspace)}`;
      },
    },
    posts: {
      query: MessagesQuery,
      defaultValue: [],
      cacheKey() {
        return `MessagesQuery:${parseInt(this.$route.params.workspace)}`;
      },
      variables() {
        return {
          offset: 0,
          workspace_id: parseInt(this.$route.params.workspace),
        };
      },
    },
    workspaceMembers: {
      defaultValue: null,
      cacheKey() {
        return `WorkspaceMembersQuery:${parseInt(
          this.$route.params.workspace
        )}`;
      },
    },
    activityLog: {
      query: ActivityLogsQuery,
      variables() {
        return {
          scope_id: parseInt(this.$route.params.workspace),
          scope_type: "App\\Workspace",
        };
      },
      cacheKey() {
        return `ActivityLogQuery:${parseInt(this.$route.params.workspace)}`;
      },
      defaultValue: null,
    },
    taskLabels: {
      defaultValue: [],
      cacheKey: "TaskLabelsQuery",
    },
    reactions: {
      defaultValue: null,
      cacheKey: "ReactionsQuery",
    },
    board: {
      defaultValue: null,
      cacheKey() {
        return `BoardQuery:${this.workspace.boards[0].id}`;
      },
    },
    document: {
      query: DocumentsQuery,
      variables() {
        return {
          workspace_id: parseInt(this.$route.params.workspace),
        };
      },
      defaultValue: [],
      cacheKey() {
        return `DocumentsQuery:${parseInt(this.$route.params.workspace)}`;
      },
    },
    customField: {
      query: CustomFieldQuery,
      variables() {
        return {
          workspace_id: parseInt(this.$route.params.workspace),
        };
      },
      cacheKey() {
        return `CustomFieldQuery:${parseInt(this.$route.params.workspace)}`;
      },
    },
    pinPosts: {
      defaultValue: [],
      query: PinMessagesQuery,
      variables() {
        return {
          workspace_id: parseInt(this.$route.params.workspace),
        };
      },
      cacheKey() {
        return `PinMessagesQuery:${parseInt(this.$route.params.workspace)}`;
      },
    },
    bookmarks: {
      defaultValue: [],
      query: BookmarksQuery,
      variables() {
        return {
          workspace_id: parseInt(this.$route.params.workspace),
        };
      },
      cacheKey() {
        return `BookmarksQuery:${parseInt(this.$route.params.workspace)}`;
      },
    },
    bookmarkFolders: {
      defaultValue: [],
      query: BookmarkFoldersQuery,
      variables() {
        return {
          workspace_id: parseInt(this.$route.params.workspace),
        };
      },
      cacheKey() {
        return `BookmarkFoldersQuery:${parseInt(this.$route.params.workspace)}`;
      },
    },
  },
  created() {
    document.addEventListener("click", this.docClickEventHandler, false);
  },
  mounted() {
    if (this.currentWorkspace) {
      this.$api.query({
        query: BoardQuery,
        variables() {
          return {
            id: this.currentWorkspace.boards[0].id,
          };
        },
        cacheKey() {
          return `BoardQuery:${this.currentWorkspace.boards[0].id}`;
        },
      });
      this.$api.query({
        query: PrivateTasksQuery,
        variables() {
          return {
            board_id: this.currentWorkspace.boards[0].id,
          };
        },
        cacheKey() {
          return `PrivateTasksQuery:${this.currentWorkspace.boards[0].id}`;
        },
      });
    }
  },
  beforeDestroy() {
    document.removeEventListener("click", this.docClickEventHandler, false);
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.$store.dispatch("activeCompany", to.params.company);
    if (from.params.workspace !== null && from.params.workspace !== undefined) {
      if (from.name === "MessagingView") {
        this.messageSearch.searchText = null;
        const query = this.$api.getQuery("NotificationsQuery");
        let notification_ids;
        if (query.data) {
          notification_ids = query.data.notifications
            .filter((n) => !n.read)
            .filter((n) => n.action.scope.id === Number(from.params.workspace))
            .filter((n) => n.action.object_type === "App\\Post")
            .map((n) => {
              n.read = true;
              return n.id;
            });
        }
        this.readMessageNotifications(notification_ids);
      }
      if (from.name === "BoardView") {
        // const query = this.$api.getQuery("NotificationsQuery");
        // let notification_ids;
        // if (query.data) {
        //   notification_ids = query.data.notifications
        //     .filter((n) => !n.read)
        //     .filter((n) => n.action.scope.id === Number(from.params.workspace))
        //     .filter(
        //       (n) =>
        //         n.action.type === "task-comment-created" ||
        //         n.action.type === "task-member-attached" ||
        //         n.action.type === "subtask-member-attached"
        //     )
        //     .map((n) => {
        //       n.read = true;
        //       return n.id;
        //     });
        // }
        // this.readBoardNotifications(notification_ids);
      }
    }
  },
  data() {
    return {
      subTaskDialog: false,
      currentTask: null,
      currentSubTask: null,
      videoCallData: {
        isDialogShow: false,
        roomName: null,
        jwtToken: null,
        window: null,
      },
      drawerLeft: "full", // "full", "mini"
      // drawerRight: "info", // null, "info", "notifications"

      profileDialog: {
        show: false,
        user: null,
      },
      taskDialog: false,
      task: null,
      subTaskId: null,
      workspace: null,
      isTaskFound: false,
    };
  },
  methods: {
    async closeNotificaiton(){
      if(this.drawerRight == "notifications") {
        this.toggleDrawerRight("notifications");
        if (!this.$store.getters.drawerState) {
          const query = this.$api.getQuery("NotificationsQuery");
          let notification_ids;
          if (query.data) {
            notification_ids = query.data.notifications
              .filter((n) => !n.read)
              .map((n) => {
                n.read = true;
                return n.id;
              });
            if (notification_ids.length) {
              const variables = {
                notification_ids: notification_ids,
                read: true,
              };
              await this.$api.mutate({
                mutation: UpdateNotificationsMutation,
                variables,
              });
            }
          }
        }
      }
    },
    async openSubtaskDialog(
      taskId,
      subtaskId,
      taskData = null,
      subtask = null
    ) {
      if (taskData) {
        this.currentTask = taskData;
      } else {
        let taskQueryData = await this.$api.query({
          query: TaskQuery,
          variables: {
            id: taskId,
          },
        });

        const taskEntity = taskQueryData.data.task;
        if (taskEntity) {
          this.currentTask = taskEntity;
        }
      }

      let subtaskEntity = null;
      if (subtask) {
        subtaskEntity = subtask;
      } else {
        let subtaskQueryData = await this.$api.query({
          query: SubtaskQuery,
          variables: {
            id: subtaskId,
          },
        });
        subtaskEntity = subtaskQueryData.data.subtask;
      }

      if (subtaskEntity) {
        this.closeTaskDialog();
        this.resetTaskDialog();
        this.subTaskDialog = true;
        this.currentSubTask = subtaskEntity;
      } else {
        this.isTaskFound = true;
        this.$router.replace({ query: null });
      }
    },
    async backToTask() {
      let isOpen = null;
      let task = this.currentTask;
      let taskDetails = {
        task,
        isOpen,
      };
      this.editTask(taskDetails);
      this.closeSubTaskDialog();
    },
    closeSubTaskDialog() {
      if (!isEmpty(this.$route.query)) {
        this.$router.replace({ query: null });
      }
      this.subTaskId = null;
      this.subTaskDialog = false;
      this.currentSubTask = null;
    },
    closeVideoCallDialog(event) {
      if (
        !meetWindow ||
        typeof event.data.isCloseDialog == "undefined"
      ) {
        return false;
      }
      event.preventDefault();
      meetWindow.close();
      return true;
    },
    closeNoTaskDialoge() {
      this.isTaskFound = false;
    },
    closeTaskDialog() {
      this.$eventBus.$emit("closeTaskDialog");
    },
    toggleDrawerLeft() {
      this.drawerLeft = this.drawerLeft === "full" ? "mini" : "full";
    },
    toggleDrawerRight(value) {
      this.$store.dispatch(
        "setDrawerState",
        this.drawerRight === value ? null : value
      );
    },
    hasClass(elem, className) {
      return elem.className.split(" ").indexOf(className) > -1;
    },
    async docClickEventHandler(event) {
      const element = event.target;
      if (this.hasClass(element, "mention")) {
        event.preventDefault();
        const member = element.dataset;
        if (member && has(member, "id")) {
          const memberObject = this.workspaceMembers.find(
            (u) => u.username === member.id
          );
          if (memberObject) {
            this.showUserInfo(memberObject);
          }
        }
      }

      if (this.hasClass(element, "taskmention")) {
        event.preventDefault();
        const taskUrl = element.dataset;
        if (taskUrl && has(taskUrl, "id")) {
          let taskData = taskUrl.id.split("-");
          this.workspace = this.$api.getEntity(
            "workspace",
            taskData[1].replace(/\D/g, "")
          );

          if (this.workspace.id != this.currentWorkspace.id) {
            this.board = this.$api.getEntity(
              "board",
              this.workspace.boards[0].id
            );
          }
          let taskEntity = this.$api.getEntity("task", taskData[0]);

          if (taskData.length > 2) {
            let subtaskEntity = this.$api.getEntity("subTask", taskData[2]);
            this.openSubtaskDialog(
              taskData[0],
              taskData[2],
              taskEntity,
              subtaskEntity
            );
            if (taskData[3]) {
              this.subTaskId = taskData[3].replace(/\D/g, "");
            }
          } else {
            if (!taskEntity) {
              let taskQueryData = await this.$api.query({
                query: TaskQuery,
                variables: {
                  id: taskData[0].replace(/\D/g, ""),
                },
              });

              taskEntity = taskQueryData.data.task;

              if (taskData[2]) {
                this.subTaskId = taskData[2].replace(/\D/g, "");
              }
            }
            if (taskEntity) {
              let isOpen = null;
              let task = taskEntity;
              let taskDetails = {
                task,
                isOpen,
              };
              this.editTask(taskDetails);
            } else {
              this.isTaskFound = true;
            }
          }
        }
      }

      if (this.hasClass(element, "open-video-call")) {
        event.preventDefault();
        const videoData = element.dataset;

        if (videoData && has(videoData, "url")) {
          let videoUrl = videoData.url;
          let userId = videoData.user;

          if (userId && userId == this.user.id) {
            let generateToken = await this.$api.mutate({
              mutation: GenerateJwtTokenForUser,
            });

            if (generateToken.data) {
              videoUrl += "?jwt=" + generateToken.data.generateJwtTokenForUser;
            }
          }

          meetWindow = window.open(
            videoUrl,
            "heycollabVideo",
            "width=600,height=600,scrollbars=yes,top=100,left=400,resizable = yes"
          );

          window.addEventListener("message", this.closeVideoCallDialog, false);
        }
      }

      if (this.hasClass(element, "copy-video-call")) {
        event.preventDefault();
        const videoData = element.dataset;

        if (videoData && has(videoData, "url")) {
          let videoUrl = videoData.url;
          this.$copyTextToClipboard(videoUrl);
        }
      }
    },
    showUserInfo(user) {
      this.profileDialog.show = true;
      this.profileDialog.user = user;
    },
    clearUserInfoDialog() {
      this.profileDialog.show = false;
      this.profileDialog.user = null;
    },
    async readMessageNotifications(notification_ids) {
      if (notification_ids.length) {
        const variables = {
          notification_ids: notification_ids,
          read: true,
        };
        await this.$api.mutate({
          mutation: UpdateNotificationsMutation,
          variables,
        });
      }
    },
    async readBoardNotifications(notification_ids) {
      if (notification_ids.length) {
        const variables = {
          notification_ids: notification_ids,
          read: true,
        };
        await this.$api.mutate({
          mutation: UpdateNotificationsMutation,
          variables,
        });
      }
    },
    closeDialogFromMessage() {
      // remove task from PrivateTasksQuery and add it to BoardQuery
      const boardQuery = this.$api.getQuery(`BoardQuery:${this.board.id}`);
      const privateTasksQuery = this.$api.getQuery(
        `PrivateTasksQuery:${this.board.id}`
      );
      const taskEntity = this.$api.getEntity("task", this.task.id);
      const updatedTask = {
        ...taskEntity,
      };
      boardQuery.data.board.tasks.push(updatedTask);
      privateTasksQuery.data.privateTasks =
        privateTasksQuery.data.privateTasks.filter(
          (t) => t.id !== this.task.id
        );
      this.shouldDeleteTask = false;
      this.taskDialog = false;
    },
  },
  computed: {
    ...mapGetters({
      activeCompany: "activeCompany",
      companies: "auth",
    }),
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
    userProfileDialog() {
      return this.profileDialog.user;
    },
    isWorkspaceOwner() {
      if (this.currentWorkspace && this.user) {
        return this.user.id === this.currentWorkspace.owner.id;
      }
      return false;
    },
    isAdmin() {
      return this.user && this.user.roles[0].label === "Admin";
      // return find(this.user.roles, o => o.name === "admin");
    },
    isPaid() {
      return this.company && this.company.subscription.status !== "free";
    },
    graceTime() {
      //GraceHours are in minutes
      if (this.currentCompany.status.graceHours / 60 > 24) {
        return (
          Math.ceil(this.currentCompany.status.graceHours / (60 * 24)) + " days"
        );
      } else if (
        this.currentCompany.status.graceHours / 60 < 24 &&
        this.currentCompany.status.graceHours / 60 >= 1
      ) {
        return Math.ceil(this.currentCompany.status.graceHours / 60) + " hours";
      } else {
        return this.currentCompany.status.graceHours + " minutes";
      }
    },
    subscriptionOver() {
      return has(this.currentCompany.status, "subscriptionOver")
        ? this.currentCompany.status.subscriptionOver
        : false;
    },
    hasPaycard() {
      return has(this.currentCompany.status, "hasPaycard")
        ? this.currentCompany.status.hasPaycard
        : true;
    },
    showFooter() {
      return this.subscriptionOver && this.isAdmin && this.isPaid;
    },
    taskWorkspaceMember() {
      if (this.workspaceMembers) {
        return this.workspaceMembers;
      } else {
        const board = this.$api.getEntity("board", this.editTaskdata.board_id);
        const workspaceMembers = this.$api.getEntity(
          "workspace",
          board.workspace_id
        ).users;
        return workspaceMembers;
      }
    },
  },
};
</script>
